import http from "./http"

//首页mobile
export function getHome() {
    return http.post(`/api/index/index.html`)
}
//首页pc
export function getMaindata() {
    return http.get(`/api/index/pc`)
}


//分类
export function getFen() {
    return http.post(`/api/WorksCategory/index`)
}
//学员成果
export function getOpus(page) {
    return http.post(`/api/WorksLists/index`,page)
}
//在线咨询
export function getconsultation() {
    let data ={
        limit:99,
    }
    return http.post(`/api/Question/index`,data)
}
//新闻中心
export function getNews(data) {
    return http.post(`/api/News/index`,data)
}
//新闻中心详情
export function getNewsDel(data) {
    return http.post(`/api/News/view`,data)
}
//站点配置
export function getPeizhi() {
    return http.post(`/api/index/config`)
}
//就业服务
export function getService() {
    return http.post(`/api/Obtains/index`)
}
//在线留言
export function postMessage(data) {
    return http.post(`/api/Enlist/add`,data)
}
//学校简介
export function getJian(id) {
    let data={
        id:id
    }
    return http.post(`/api/Introduction/view`,data)
}
//师资分类
export function getSF() {
    return http.post(`/api/TeacherCategory/index`)
}
//师资列表Teacher/index
export function getSList(data) {
    return http.post(`/api/Teacher/index`,data)
}
//课程分类
export function getCourse() {
    return http.post(`/api/CourseCategory/index`)
}
//课程分类详情
export function getCourseDel(data) {
    return http.post(`/api/CourseCategory/view`,data)
}
//师资详情
export function getTeaDel(data) {
    return http.post(`/api/Teacher/view`,data)
}
//banner图
export function getBanner(data) {
    return http.post(`/api/Banner/getOne`,data)
}
//在线咨询PC
export function getZiXunPC(data) {
    return http.post(`/api/Question/pc`,data)
}
//navlist PC
export function getNavlist(){
    return http.get(`/api/Column/pc`)
}
//手机栏目
export function getMobLan(){
    return http.get(`/api/Column/mobile`)
}
//页面标语
export function getBiaoyu(data){
    return http.post(`/api/Slogan/view`,data)
}
//服务标签
export function getFuwu(){
    return http.post(`/api/Service/index`)
}
//就业详情
export function getWorkde(id){
    let data={
        id:id
    }
    return http.post(`/api/Obtains/view`,data)
}