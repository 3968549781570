<template>
  <div class="home">
    <!-- 头部 -->
    <div class="headbox">
      <div class="logobox">
        <img src="../assets/pc/logo_03.png" class="logo" />
      </div>
      <div class="navbox">
        <ul>
          <li v-for="(a, i) in navList" :key="i" @click="toNav(a)" :class="{ btn_on: chose == a.keyPath }">
            {{ a.text }}
            <p>{{ a.english_title }}</p>
          </li>
        </ul>
      </div>
      <div class="phone">
        <div style="display: flex;">
          <img style="position: relative;top: -5px;width: 1.88rem;height: 1.88rem;" src="../assets/pc/phone.png" alt="" />
          <div style="width: 12.5rem;height: 1.25rem;font-size: 1.5rem;margin-left: 8px;color: #fff;display: flex;align-items: center;">{{ tel }}</div>
        </div>
      </div>
    </div>

    <!--轮播  -->
    <!-- <el-carousel arrow="never" class="swiper" trigger="click">
      <el-carousel-item class="swiper" v-for="(item, i) in bannerList" :key="i">
        <img :src="item.banner_img" class="swipeimg" />
      </el-carousel-item>
    </el-carousel> -->
    <!-- <div class="swiper">
      <img src="../assets/pc/banner.jpg" alt="" class="swipeimg" />
    </div> -->

    <!--悬浮  -->
    <div class="backtop">
      <div id="qq" @click="toQQ">
        <img src="../assets/pc/r_icon01_06.png" />
        <p>在线咨询</p>
      </div>
      <div @click="toContact">
        <img src="../assets/pc/r_icon02_06.png" />
        <p>在线留言</p>
      </div>
      <el-popover popper-class="popoverStyle" placement="left" title="联系电话：" width="200" trigger="hover" :content="tel">
        <div slot="reference">
          <img src="../assets/pc/r_icon03_06.png" />
          <p>联系我们</p>
        </div>
      </el-popover>

      <div class="topicon" @click="scrollTop"></div>
    </div>
    <!-- <div class="backtop" >
      <img src="../assets/pc/totop.jpg" />
    </div> -->

    <!-- 内容 -->
    <div class="pcbox">
      <router-view></router-view>

      <!-- 底部 -->
      <div>
        <div class="footnav">
          <div>
            <div v-for="(a, i) in navList" :key="i" @click="toNav(a)">
              {{ a.text }}
            </div>
          </div>
        </div>
        <div class="ba">
          <h6>
            <span @click="goto">{{ config.copyright }}</span>
            <img style="width: 1rem" src="../assets/pc/image 2@2x.png" alt="" />
            <span @click="go()">技术支持：寰宇网络</span>
          </h6>
        </div>
        <!-- <div class="foot">
          <div class="footxt" style="display: flex">
            <img src="../assets/pc/icon_tel_03.png" alt="" />
            <div style="text-align: left">
              <p>咨询热线:</p>
              <p>{{ config.tel }}</p>
            </div>
          </div>
          <div class="footxt">
            <p>{{ config.add }}</p>
          </div>
        </div> -->
      </div>
      <!--  -->
    </div>
  </div>
</template>
<script>

import { getMaindata, getNavlist } from "@/api/api";
//  <script src="https://www.yingyuchat.com/static/js/warbler-front.js">
export default {
  name: "HomeView",
  data() {
    return {
      bannerList: [],
      navList: [],
      itemList: [
        {
          text: "",
          url: "",
        },
      ],
      scrollTopNum: "", //页面滚动的高度
      chose: "maindata",
      tel: "",
      config: "",
      qqNumber: "",
    };
  },
  created() {
    getMaindata().then((res) => {
      this.bannerList = res.data.banner;
      this.tel = res.data.config.tel;
      this.config = res.data.config;
      this.qqNumber = this.config.qq;
    });
    getNavlist().then((res) => {
      this.navList = res.data;
    });
  },
  mounted() {
    this.chose = localStorage.getItem("navkey");
  },
  watch: {
    $route(to, from) {
      // console.log(to);
      this.chose = to.name;
    },
  },
  methods: {
    toQQ() {
      const qqUrl = "https://tb.53kf.com/code/client/bff303bb0c20560c194557ae310f60dc2/1";
      window.open(qqUrl, 'windowForBridgeIM', 'left=100,top=200,width=680,height=580,location=no,resizable=yes,status=no,toolbar=no,menubar=no');
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    toNav(a) {
      this.chose = a.keyPath;
      // 存储
      localStorage.setItem("navkey", a.keyPath);
      this.$router.push({
        name: a.keyPath,
      });
    },
    toContact() {
      this.$router.push({
        name: "contact",
        params: { id: 1 },
      });
    },
    go() {
      const Url = `https://www.hyuwl.cn`;
      window.open(Url);
    },
    goto() {
      const Url = `https://www.beian.gov.cn/portal/registerSystemInfo`;
      window.open(Url);
    },
  },
};
</script>
<style lang="less">
.home {
  background-color: #0b1a24;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  min-height: 100vh;
}

/deep/ .el-popover__title {
  font-size: 1.2rem !important;
}

/deep/ .el-popover {
  color: rgb(9, 30, 247);
}

/** iPad 和 windows**/
@media only screen and (min-width: 768px) {
  .headbox {
    position: sticky;
    top: 0;
    padding-top: 10px;
    z-index: 9;
    background-color: #0B1A24;
    // background-color: rgba(11, 26, 36, 0.2);
  }

  .logobox {
    // width: 180px;
    height: 56px;
    vertical-align: middle;
    align-items: center;
    // display: inline-block;
    float: left;
    margin-left: 90px;
  }

  .logo {
    width: 100%;
    height: 100%;
    float: left;
  }

  // .swiper {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 56rem;
  // }

  .swipeimg {
    width: 100%;
    height: 100%;
  }

  .navbox {
    display: inline-block;

    ul {
      height: 5.25rem;
      line-height: 2rem;
      color: white;
    }

    li {
      width: 8.44rem;
      height: 5.25rem;
      // margin-right: 3rem;
      font-size: 1.13rem;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      padding-top: 10px;
    }

    li:hover,
    .btn_on {
      background: url(../assets/pc/conbg.png) no-repeat;
      background-size: 100%;
    }

    p {
      font-size: 0.75rem;
    }
  }

  .phone {
    display: inline-block;

    // vertical-align: middle;
  }

  // .pcbox {
  // position: relative;
  // margin-top: 56rem;
  // }

  .foot {
    width: 93rem;
    margin: 0 auto;
    height: 6rem;
    align-items: center;
    vertical-align: middle;
    display: flex;
    text-align: center;
  }

  .foot>img {
    width: 12.38rem;
    height: 12.38rem;
    margin: 88px 60px 0;
    display: inline-block;
    position: relative;
  }

  .footxt {
    margin: 0px 60px 0 0;
    position: relative;

    p {
      color: #fff;
    }

    img {
      width: 3.1rem;
      height: 3.1rem;
    }
  }

  .footxt p:nth-of-type(1) {
    height: 18px;
    font-size: 1.13rem;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .footxt p:nth-of-type(2) {
    height: 36px;
    width: 280px;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .footnav {
    height: 3.5rem;
    width: 93rem;
    line-height: 3.5rem;
    margin: 6rem auto 0;
    background: url(../assets/pc/footline.png) no-repeat;
    background-size: 100%;
    border-image: linear-gradient(0deg, #00d1ce, #00d1ce, #00d1ce) 10 10;

    div {
      cursor: pointer;
    }
  }

  .footnav div:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
  }

  .footnav>div {
    color: #ffffff;
    font-size: 1.13rem;
  }

  .kefu>img {
    width: 100%;
    height: 100%;
  }

  .backtop {
    width: 7rem;
    height: 20rem;
    text-align: center;
    color: #fff;
    font-size: 1rem;
    position: fixed;
    bottom: 150px;
    right: 0;
    z-index: 10;
    background: url(../assets/pc/bg.png) no-repeat;
    background-size: 100%;

    div {
      cursor: pointer;
    }

    img {
      width: 3rem;
      height: 3rem;
      margin-top: 1.2rem;
    }
  }

  .topicon {
    width: 8rem;
    height: 3.25rem;
    position: absolute;
    bottom: 0;
    left: 18%;
  }

  .ba {
    width: 100%;
    margin: 80px auto 0;
    padding-bottom: 40px;

    h6 {
      height: 60px;
      line-height: 60px;
      font-size: 1.13rem;
      color: #fff;
      background-color: #06151e;
    }
  }
}

.el-popover.popoverStyle {
  padding: 20px;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 800;
  background-color: #188685;
  border: 0;
  color: #fff;
}

.popoverStyle .el-popover__title {
  color: #fff;
  font-size: 1.2rem;
}

.popoverStyle .popper__arrow::after {
  border-left-color: #188685 !important;
}

.el-popover.popoverS {
  padding: 20px;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 800;
  background-color: #188685;
  border: 0;
  color: #fff;
}

.popoverS .popper__arrow::after {
  border-left-color: #188685 !important;
}

.popoverS .popper__arrow::after {
  border-right-color: #188685 !important;
}

#KFLOGO {
  // display: none;
}
</style>