import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";

Vue.use(VueRouter);

const routesPC = [
  {
    path: "/",
    redirect: "/maindata",
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/maindata",
        name: "maindata",
        component: () => import("../views/pagePc/maindata.vue"),
      },
      {
        path: "/result",
        name: "result",
        component: () => import("../views/pagePc/result.vue"),
      },
      {
        path: "/services",
        name: "services",
        component: () => import("../views/pagePc/services.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("../views/pagePc/contact.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/pagePc/news.vue"),
      },
      {
        path: "/application",
        name: "application",
        component: () => import("../views/pagePc/application.vue"),
      },
      {
        path: "/faculty",
        name: "faculty",
        component: () => import("../views/pagePc/faculty.vue"),
      },
      {
        path: "/project",
        name: "project",
        component: () => import("../views/pagePc/project.vue"),
      },
      {
        path: "/introduction",
        name: "introduction",
        component: () => import("../views/pagePc/introduction.vue"),
      },
      {
        path: "/detail",
        name: "detail",
        component: () => import("../views/pagePc/detail.vue"),
      },
      {
        path: "/detail_s",
        name: "detail_s",
        component: () => import("../views/pagePc/detail_s.vue"),
      },
      {
        path: "/detail_n",
        name: "detail_n",
        component: () => import("../views/pagePc/detail_n.vue"),
      },
      {
        path: "/work",
        name: "work",
        component: () => import("../views/pagePc/work.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const routesM = [
  {
    path: "/",
    redirect: "/mainpage",
  },
  {
    path: "/",
    name: "homePhone",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/homePhone.vue"),
    children: [
      {
        path: "/mainpage",
        name: "mainpage",
        component: () => import("../views/pageMobile/mainpage.vue"),
      },
      {
        path: "/project",
        name: "project",
        component: () => import("../views/pageMobile/project.vue"),
      },
      {
        path: "/application",
        name: "application",
        component: () => import("../views/pageMobile/application.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/pageMobile/news.vue"),
      },
      {
        path: "/opus",
        name: "opus",
        component: () => import("../views/pageMobile/opus.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("../views/pageMobile/contact.vue"),
      },
      {
        path: "/faculty",
        name: "faculty",
        component: () => import("../views/pageMobile/faculty.vue"),
      },
      {
        path: "/services",
        name: "services",
        component: () => import("../views/pageMobile/services.vue"),
      },
      {
        path: "/introduction",
        name: "introduction",
        component: () => import("../views/pageMobile/introduction.vue"),
      },
      {
        path: "/detail",
        name: "detail",
        component: () => import("../views/pageMobile/detail.vue"),
      },
      {
        path: "/detail_s",
        name: "detail_s",
        component: () => import("../views/pageMobile/detail_s.vue"),
      },
      {
        path: "/detail_n",
        name: "detail_n",
        component: () => import("../views/pageMobile/detail_n.vue"),
      },
      {
        path: "/work",
        name: "work",
        component: () => import("../views/pageMobile/work.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
];
//判断是否为移动设备
// var routes = [];
// if (
//   navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   )
// ) {
//   routes = routesM;
// } else {
//   routes = routesPC;
// }
const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};

const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  base: process.env.BASE_URL,
  routes: ISMOBILE() ? routesM : routesPC,
});

/**
 * 判断是否为移动设备，是，则跳转到移动端的路径
 */
// router.beforeEach((to, from, next) => {
//   if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
//     // window.location.href = ''
//     return
//   }
//   next()
// })

export default router;
